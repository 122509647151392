import { Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import { useViews } from 'datacosmos/stores/ViewsProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import type {
  DialogListRenderer,
  DialogItemDetailsRenderer,
} from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import DatacosmosSelectDialog from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import s from 'datacosmos/components/Header/DatacosmosHeader.module.scss';
import DeleteViewDialog from './DeleteViewDialog';
import SaveViewDialog from './SaveViewDialog';
import {
  screenshot,
  screenshotLinkToUsableImage,
  screenshotMimeToExtension,
  vectorMimeTypes,
} from 'utils/screenshot';
import type { MimeType as ScMimeType } from 'utils/screenshot';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import classNames from 'classnames';
import type { Scenario } from '_api/scenarios/types';
import moment from 'moment';
import { DATETIME_FORMAT } from 'constants/datetime';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Popover2 } from '@blueprintjs/popover2';
import PrimaryButton from '_molecules/Button/PrimaryButton';
import placeholderImage from 'images/datacosmos/img-placeholder.svg';
import OptionsDialog from '_organisms/OptionsDialog/OptionsDialog';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import GeoPDFDetails from '../GeoPDFDetailsModal';
import { uploadFileToProject } from '_api/geopdf/service';
import {
  convertLayersToFeatureCollection,
  downloadGeojsonAsKml,
  downloadGeojsonAsKmz,
  downloadGeojsonAsShp,
  downloadAOIasGeojson,
} from 'datacosmos/download/geojson';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import { Button } from 'opencosmos-ui';
import {
  isENVIPartiallyUploaded,
  isShpPartiallyUploaded,
} from 'utils/fileUpload';
interface IProps {
  currentScenario?: Scenario;
  fetchCurrentProjectItems: () => Promise<void>;
}

const Views = ({ currentScenario, fetchCurrentProjectItems }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState<boolean>(false);
  const [isSavingView, setIsSavingView] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const uploadFileRef = useRef<HTMLInputElement>(null);

  const [isChangeFormatDialogOpen, setIsChangeFormatDialogOpen] =
    useState<boolean>(false);

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const [isOverwriteDialogOpen, setIsOverwriteDialogOpen] =
    useState<boolean>(false);

  const {
    handleViewOverwrite,
    handleViewSave,
    isSaveEnabled,
    views,
    currentView,
    setCurrentView,
    handleViewDelete,
    initUntitledView,
    handleViewChange,
    loading,
    savedMime,
    setSavedMime,
    isIncludeWatermark,
  } = useViews();

  const { isMinimal } = useViewMode();

  const [viewToDelete, setViewToDelete] = useState<ProviderView | undefined>(
    currentView
  );

  const history = useHistory();
  const location = useLocation();

  const { mapRef } = useMap();

  const { layers } = useMapLayers();

  const [selectedView, setSelectedView] = useState<ProviderView>();

  const [viewScreenshot, setViewScreenshot] = useState<string | undefined>(
    placeholderImage
  );

  const downloadFileName = `open_cosmos_screenshot_${new Date().toISOString()}`;

  const [isGeoPdfDetailsOpen, setIsGeoPdfDetailsOpen] =
    useState<boolean>(false);
  // Cache fetcehd screenshots to avoid refetching
  const [cachedScreenshots, setCachedScreenshots] = useState<
    { screenshot: string | undefined; viewId: string }[]
  >([]);

  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);

  const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);

  const { hasPermission: canWriteToProject } = useCheckPermissions({
    permissions: {
      type: 'datacosmos_scenario',
      actionScope: 'data:scenario:write',
      id: String(currentScenario?.id),
    },
  });

  useEffect(() => {
    if (!selectedView) {
      return;
    }
    const foundSc = cachedScreenshots.find(
      (sc) => sc.viewId === selectedView.id
    );
    if (foundSc) {
      setViewScreenshot(foundSc.screenshot);
      return;
    }

    screenshotLinkToUsableImage(selectedView.screenshot_link)
      .then((res) => {
        setViewScreenshot(res);
        setCachedScreenshots([
          ...cachedScreenshots,
          { screenshot: res, viewId: selectedView.id },
        ]);
      })
      .catch(() => {});
  }, [cachedScreenshots, selectedView]);

  const geoJsonFromLayers = useMemo(() => {
    return convertLayersToFeatureCollection(layers);
  }, [layers]);

  const downloadVectorScreenshot = useCallback(
    (mimeFormat: string) => {
      switch (mimeFormat) {
        case 'GeoJSON':
        case 'file/geojson':
          setSavedMime('file/geojson');
          downloadAOIasGeojson(geoJsonFromLayers, downloadFileName);
          setIsDownloadingFile(false);

          break;
        case 'KML':
        case 'application/vnd.google-earth.kml+xml':
          setSavedMime('application/vnd.google-earth.kml+xml');
          downloadGeojsonAsKml(
            geoJsonFromLayers,
            downloadFileName,
            isIncludeWatermark
          );
          setIsDownloadingFile(false);

          break;
        case 'KMZ':
        case 'application/vnd.google-earth.kmz':
          setSavedMime('application/vnd.google-earth.kmz');
          void downloadGeojsonAsKmz(
            geoJsonFromLayers,
            downloadFileName,
            isIncludeWatermark
          );
          setIsDownloadingFile(false);

          break;
        case 'SHP':
        case 'application/vnd.shp':
          setSavedMime('application/vnd.shp');
          void downloadGeojsonAsShp(geoJsonFromLayers, downloadFileName);
          setIsDownloadingFile(false);

          break;
        default:
          break;
      }
    },
    [geoJsonFromLayers, setSavedMime, downloadFileName, isIncludeWatermark]
  );

  const downloadScreenshot = useCallback(
    (mimeType?: ScMimeType) => {
      setIsDownloadingFile(true);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const container = mapRef.current?.getContainer
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          (mapRef.current.getContainer() as HTMLDivElement)
        : null;

      if (!container) {
        setIsDownloadingFile(false);
        return;
      }

      const mime = mimeType ?? savedMime;
      setSavedMime(mime);

      if (mimeType === 'application/pdf') {
        setIsGeoPdfDetailsOpen(true);
        setIsDownloadingFile(false);
        return;
      }

      if (vectorMimeTypes.includes(mime)) {
        downloadVectorScreenshot(mime);
        return;
      }

      void screenshot(container, {
        mimeType: mime,
        ignore: 'zoom-controls',
        includeWaterMark: isIncludeWatermark,
      }).then((base64Image) => {
        const downloadLink = document.createElement('a');
        downloadLink.download = `${downloadFileName}${screenshotMimeToExtension(
          mime
        )}`;
        downloadLink.href = base64Image;
        downloadLink.dataset.downloadurl = [
          mime,
          downloadLink.download,
          downloadLink.href,
        ].join(':');

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        downloadLink.remove();
        setIsDownloadingFile(false);
      });
    },
    [
      mapRef,
      savedMime,
      setSavedMime,
      isIncludeWatermark,
      downloadFileName,
      downloadVectorScreenshot,
      setIsDownloadingFile,
    ]
  );

  const mimeDialogOptions = useMemo(
    () => [
      {
        optionType: 'Raster',
        formats: [
          {
            name: 'PNG',
            onClick: () => {
              setSavedMime('image/png');
              downloadScreenshot('image/png');
            },
          },
          {
            name: 'JPEG',
            onClick: () => {
              setSavedMime('image/jpeg');
              downloadScreenshot('image/jpeg');
            },
          },
          {
            name: 'GIF',
            onClick: () => {
              setSavedMime('image/gif');
              downloadScreenshot('image/gif');
            },
          },
          {
            name: 'TIFF',
            onClick: () => {
              setSavedMime('image/tiff');
              downloadScreenshot('image/tiff');
            },
          },
          {
            name: 'GeoPDF',
            onClick: () => {
              setSavedMime('application/pdf');
              downloadScreenshot('application/pdf');
            },
          },
        ],
      },
      {
        optionType: 'Vector',
        formats: [
          {
            name: 'GeoJSON',
          },
          {
            name: 'KML',
          },
          {
            name: 'KMZ',
          },
          {
            name: 'SHP',
          },
        ],
      },
    ],
    [downloadScreenshot, setSavedMime]
  );

  const renderViews: DialogListRenderer<ProviderView> = (item, select) => {
    if (loading) {
      return <Spinner />;
    }
    return (
      <div
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={() => {
          select();
          setViewToDelete(item);
        }}
      >
        <span>{item.name}</span>
      </div>
    );
  };

  const viewDetailsRenderer: DialogItemDetailsRenderer<ProviderView> = (
    view,
    open
  ) => {
    return (
      <div className="flex flex-col grow h-full p-2 pb-8 gap-2">
        <div className="flex flex-col gap-6">
          <h2 className="p-0 m-0">{view.name}</h2>

          <span>{view.description}</span>

          <img
            src={viewScreenshot}
            alt="View screenshot"
            className="max-h-52 h-full"
          />

          {/* TODO: Implement when available */}
          <span>
            {translate('datacosmos.viewsDialog.sharedWith', { with: 'n/a' })}
          </span>

          <span data-testid="datacosmos-view-panel-created-at">
            {translate('datacosmos.viewsDialog.createdAt', {
              at: moment(view.created_at).format(DATETIME_FORMAT),
            })}
          </span>
          <span data-testid="datacosmos-view-panel-updated-at">
            {translate('datacosmos.viewsDialog.lastUpdated', {
              at: moment(view.updated_at).format(DATETIME_FORMAT),
            })}
          </span>
        </div>

        <div className="flex items-center justify-evenly gap-2">
          <Button
            isMinimal
            iconPlacement="left"
            icon="Folder"
            onPress={() => {
              open(view);
              setSelectedView(undefined);
              sendInfo({
                type: ` Opened a view: ${view.id} - ${view.name} `,
                action: 'Click',
                item: 'View',
                module: 'DataCosmos',
                additionalParams: {
                  view: view,
                },
              });
            }}
          >
            {translate('datacosmos.viewsDialog.openView')}
          </Button>

          <Button
            isMinimal
            icon="Share"
            iconPlacement="left"
            onPress={() => {
              history.push(`/resource/view/${view.id}`);
            }}
          >
            {translate('datacosmos.viewsDialog.shareView')}
          </Button>

          <Button
            isMinimal
            icon="Trash"
            iconPlacement="left"
            onPress={() => {
              setIsDeleteDialogOpen(true);
            }}
          >
            {translate('datacosmos.viewsDialog.deleteView')}
          </Button>
        </div>
      </div>
    );
  };

  const handleViewOpen = (view: ProviderView) => {
    sendInfo({
      type: `View select: ${view.id} - ${view.name} `,
      action: 'Click',
      item: 'View',
      module: 'DataCosmos',
      additionalParams: {
        project: view,
      },
    });
    setCurrentView(view);
    handleViewChange(view);
    setIsOpen(false);
    history.push(`${location.pathname}?view=${view.id}`);
  };

  const returnToBlankView = () => {
    sendInfo({
      type: 'Free edit mode select',
      action: 'Click',
      item: 'Free edit mode',
      module: 'DataCosmos',
    });
    initUntitledView();
    setIsOpen(false);
    history.push(`${location.pathname}`);
  };

  const resetUploadFileRef = () => {
    if (uploadFileRef.current?.value) {
      uploadFileRef.current.value = '';
    }
  };
  return (
    <div
      style={{
        marginLeft: '5px',
        display: 'flex',
        gap: '0.7rem',
        alignItems: 'center',
      }}
    >
      <span
        className={classNames(s.scenarioName, {
          'text-item-contrast dark:text-item-contrast cursor-default':
            isMinimal,
        })}
        data-testid="open-views-modal-button"
        onClick={() => {
          if (isMinimal) return;
          sendInfo({
            type: 'View selection open',
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
          });
          setIsOpen(true);
        }}
      >
        |{' '}
        {currentView?.id
          ? currentView.name
          : translate('datacosmos.header.freeEditingMode')}
      </span>
      {isSaveEnabled && !isMinimal && (
        <Popover2
          position="bottom"
          interactionKind="hover"
          content={
            <div className="p-2 flex items-center gap-2 dark:bg-surface-dark dark:text-item-dark-contrast ">
              {translate('datacosmos.tooltips.header.saveView')}
            </div>
          }
        >
          <Button
            icon={IconNames.FLOPPY_DISK}
            className="bg-transparent dark:bg-transparent"
            isMinimal
            size="base"
            onPress={() => {
              sendInfo({
                type: 'View saved',
                action: 'Click',
                item: 'View',
                module: 'DataCosmos',
              });
              currentView?.id
                ? setIsOverwriteDialogOpen(true)
                : setIsSaveDialogOpen(true);
            }}
            loading={isSavingView}
          />
        </Popover2>
      )}

      {isDownloadingFile ? (
        <Spinner size={18} />
      ) : (
        <Popover2
          interactionKind="hover"
          position="bottom"
          disabled={layers.length === 0}
          content={
            <div className="p-2 flex items-center gap-2 dark:bg-surface-dark dark:text-item-dark-contrast ">
              <span>
                {translate('datacosmos.tooltips.header.downloadScreenshot', {
                  format:
                    vectorMimeTypes.includes(savedMime) &&
                    savedMime.includes('.')
                      ? savedMime?.substring(savedMime.lastIndexOf('.'))
                      : screenshotMimeToExtension(savedMime),
                })}
              </span>

              <PrimaryButton
                text={translate('datacosmos.optionsDialog.changeFormat')}
                onPress={() => setIsChangeFormatDialogOpen(true)}
              />
            </div>
          }
        >
          <Button
            icon={IconNames.CAMERA}
            className="bg-transparent dark:bg-transparent"
            isMinimal
            size="base"
            onPress={() => {
              if (layers.length === 0) {
                return;
              }
              sendInfo({
                type: 'Screenshot taken',
                action: 'Click',
                item: 'Screenshot',
                module: 'DataCosmos',
                additionalParams: {
                  format: screenshotMimeToExtension(savedMime),
                },
              });
              if (savedMime === 'application/pdf') {
                setIsGeoPdfDetailsOpen(true);
                return;
              }
              downloadScreenshot();
            }}
            isDisabled={layers.length === 0}
          />
        </Popover2>
      )}
      <Popover2
        interactionKind="hover"
        position="bottom"
        content={
          <div className="p-2 flex items-center gap-2 dark:bg-surface-dark dark:text-item-dark-contrast ">
            <span>
              {canWriteToProject
                ? translate('datacosmos.tooltips.header.uploadFile')
                : translate('datacosmos.tooltips.header.noUploadPermissions')}
            </span>
          </div>
        }
      >
        {isUploadingFile ? (
          <Spinner size={18} />
        ) : (
          <Button
            icon="Upload"
            className="bg-transparent dark:bg-transparent"
            isMinimal
            size="base"
            onPress={() => {
              uploadFileRef.current?.click();
            }}
            isDisabled={!canWriteToProject}
          />
        )}
      </Popover2>
      <DatacosmosSelectDialog<ProviderView>
        items={views.filter(
          (v) => currentScenario && v.project === currentScenario.id
        )}
        title={translate('datacosmos.viewsDialog.views')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        listRenderer={renderViews}
        listItemClickHandler={handleViewOpen}
        handleAddItemClick={returnToBlankView}
        viewAllTitle={translate('datacosmos.viewsDialog.showAllViews')}
        handleViewAllClick={() => history.push(`/data/views/`)}
        addItemTitle={translate(
          'datacosmos.viewsDialog.returnToFreeEditingMode'
        )}
        handleItemDetailsRender={viewDetailsRenderer}
        sortListBy="name"
        selectedItem={selectedView}
        setSelectedItem={(view) => {
          setSelectedView(view);
          sendInfo({
            type: `Selected a view: ${view?.id ?? 'no id'} - ${
              view?.name ?? 'no name'
            } `,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              view: view,
            },
          });
        }}
        isContentLoading={loading}
      />
      <SaveViewDialog
        view={currentView}
        isOpen={isSaveDialogOpen}
        setIsOpen={setIsSaveDialogOpen}
        handleSave={async (name, desc) => {
          setIsSavingView(true);
          await handleViewSave(name, desc);
          setIsSavingView(false);
          sendInfo({
            type: `Save new view: ${name}`,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              viewName: name,
              viewDescription: desc,
            },
          });
        }}
      />
      <SaveViewDialog
        view={currentView}
        isOpen={isOverwriteDialogOpen}
        setIsOpen={setIsOverwriteDialogOpen}
        handleSave={async (name, desc, id) => {
          setIsSavingView(true);
          if (id) await handleViewOverwrite(name, desc, id);
          setIsSavingView(false);
          setSelectedView(undefined);
          sendInfo({
            type: `Overwrite existing view; overwritten view ${
              id ?? 'unknown'
            } with view ${name} `,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              viewName: name,
              viewDescription: desc,
            },
          });
        }}
        overwrite
      />
      <DeleteViewDialog
        view={viewToDelete}
        deleteHandler={async (viewId) => {
          await handleViewDelete(viewId);
          setSelectedView(undefined);
        }}
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      />
      <OptionsDialog
        options={mimeDialogOptions}
        isOpen={isChangeFormatDialogOpen}
        setIsOpen={setIsChangeFormatDialogOpen}
        title={translate('datacosmos.optionsDialog.changeFormat')}
        showOptionsUnderTabs
        onClickHandler={(formatName) => downloadVectorScreenshot(formatName)}
      />
      <input
        onChange={async (e) => {
          const uploadedFiles = e.target.files;

          if (!uploadedFiles?.length) {
            resetUploadFileRef();
            return;
          }

          if (isENVIPartiallyUploaded(uploadedFiles)) {
            resetUploadFileRef();
            return;
          }

          if (isShpPartiallyUploaded(uploadedFiles)) {
            resetUploadFileRef();
            return;
          }

          const formData = new FormData();
          for (const file of uploadedFiles) {
            formData.append('files', file);
          }

          setIsUploadingFile(true);
          const { success } = await uploadFileToProject({
            params: { projectId: currentScenario?.id ?? '' },
            body: formData,
          });
          if (success) {
            await fetchCurrentProjectItems();
          }
          setIsUploadingFile(false);

          resetUploadFileRef();
        }}
        ref={uploadFileRef}
        multiple
        type={'file'}
        style={{ display: 'none' }}
        accept={
          '.nc4, .dat, .hdr, image/tiff, .img, .kml, .kmz, .geojson, .shp, .dbf, .shx, .prj'
        }
      />
      <GeoPDFDetails
        isOpen={isGeoPdfDetailsOpen}
        setIsOpen={setIsGeoPdfDetailsOpen}
      />
    </div>
  );
};

export default Views;
